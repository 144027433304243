import { render, staticRenderFns } from "./LeaveMessageDiaLog.vue?vue&type=template&id=ed0dca34"
import script from "./LeaveMessageDiaLog.vue?vue&type=script&lang=js"
export * from "./LeaveMessageDiaLog.vue?vue&type=script&lang=js"
import style0 from "./LeaveMessageDiaLog.vue?vue&type=style&index=0&id=ed0dca34&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports